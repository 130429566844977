var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "ais-instant-search",
        {
          attrs: {
            "search-client": _vm.searchClient,
            "index-name": "instant_search",
            id: "algolia-instant-search-demo",
          },
        },
        [
          _c("ais-configure", { attrs: { hitsPerPage: 9 } }),
          _c("div", { staticClass: "algolia-header mb-4" }, [
            _c(
              "div",
              {
                staticClass:
                  "flex md:items-end items-center justify-between flex-wrap",
              },
              [
                _c("feather-icon", {
                  staticClass: "inline-flex lg:hidden cursor-pointer mr-4",
                  attrs: { icon: "MenuIcon" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.toggleFilterSidebar.apply(null, arguments)
                    },
                  },
                }),
                _c(
                  "p",
                  {
                    staticClass:
                      "lg:inline-flex hidden font-semibold algolia-filters-label",
                  },
                  [_vm._v("Filters")]
                ),
                _c(
                  "div",
                  { staticClass: "flex justify-between items-end flex-grow" },
                  [
                    _c("ais-stats", {
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({
                            hitsPerPage,
                            nbPages,
                            nbHits,
                            page,
                            processingTimeMS,
                            query,
                          }) {
                            return _c(
                              "p",
                              { staticClass: "font-semibold md:block hidden" },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(nbHits) +
                                    " results found in " +
                                    _vm._s(processingTimeMS) +
                                    "ms\n                        "
                                ),
                              ]
                            )
                          },
                        },
                      ]),
                    }),
                    _c(
                      "div",
                      { staticClass: "flex flex-wrap" },
                      [
                        _c("ais-sort-by", {
                          attrs: {
                            items: [
                              { value: "instant_search", label: "Featured" },
                              {
                                value: "instant_search_price_asc",
                                label: "Lowest Price",
                              },
                              {
                                value: "instant_search_price_desc",
                                label: "Highest Price",
                              },
                            ],
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({
                                items,
                                currentRefinement,
                                refine,
                              }) {
                                return _c(
                                  "vs-select",
                                  {
                                    staticClass:
                                      "mr-4 vs-input-shadow-drop vs-select-no-border d-theme-input-dark-bg w-48",
                                    attrs: { value: currentRefinement },
                                    on: { input: (val) => refine(val) },
                                  },
                                  _vm._l(items, function (item) {
                                    return _c("vs-select-item", {
                                      key: item.value,
                                      attrs: {
                                        value: item.value,
                                        text: item.label,
                                      },
                                    })
                                  }),
                                  1
                                )
                              },
                            },
                          ]),
                        }),
                        _c(
                          "div",
                          [
                            _c("feather-icon", {
                              staticClass:
                                "p-2 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer",
                              attrs: {
                                icon: "GridIcon",
                                svgClasses: {
                                  "text-primary stroke-current":
                                    _vm.currentItemView == "item-grid-view",
                                },
                              },
                              on: {
                                click: function ($event) {
                                  _vm.currentItemView = "item-grid-view"
                                },
                              },
                            }),
                            _c("feather-icon", {
                              staticClass:
                                "p-2 ml-4 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer hidden sm:inline-flex",
                              attrs: {
                                icon: "ListIcon",
                                svgClasses: {
                                  "text-primary stroke-current":
                                    _vm.currentItemView == "item-list-view",
                                },
                              },
                              on: {
                                click: function ($event) {
                                  _vm.currentItemView = "item-list-view"
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "relative clearfix",
              attrs: { id: "algolia-content-container" },
            },
            [
              _c(
                "vs-sidebar",
                {
                  staticClass:
                    "items-no-padding vs-sidebar-rounded background-absolute",
                  attrs: {
                    parent: "#algolia-content-container",
                    "click-not-close": _vm.clickNotClose,
                    "hidden-background": _vm.clickNotClose,
                  },
                  model: {
                    value: _vm.isFilterSidebarActive,
                    callback: function ($$v) {
                      _vm.isFilterSidebarActive = $$v
                    },
                    expression: "isFilterSidebarActive",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "p-6 filter-container" },
                    [
                      _c("h6", { staticClass: "font-bold mb-3" }, [
                        _vm._v("Multi Range"),
                      ]),
                      _c("ais-numeric-menu", {
                        attrs: { attribute: "price", items: _vm.numericItems },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ items, refine, createURL }) {
                              return _c(
                                "ul",
                                {},
                                _vm._l(items, function (item) {
                                  return _c(
                                    "li",
                                    {
                                      key: item.value,
                                      staticClass:
                                        "flex items-center cursor-pointer py-1",
                                      on: {
                                        click: function ($event) {
                                          return refine(item.value)
                                        },
                                      },
                                    },
                                    [
                                      _c("feather-icon", {
                                        attrs: {
                                          icon: "CircleIcon",
                                          svgClasses: [
                                            {
                                              "text-primary fill-current":
                                                item.isRefined,
                                            },
                                            "h-5 w-5",
                                          ],
                                        },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "ml-2",
                                          class: {
                                            "text-primary": item.isRefined,
                                          },
                                        },
                                        [_vm._v(_vm._s(item.label))]
                                      ),
                                    ],
                                    1
                                  )
                                }),
                                0
                              )
                            },
                          },
                        ]),
                      }),
                      _c("vs-divider"),
                      _c("h6", { staticClass: "font-bold mb-3" }, [
                        _vm._v("Price Slider"),
                      ]),
                      _c("ais-range-input", {
                        attrs: { attribute: "price" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({
                              currentRefinement,
                              range,
                              refine,
                            }) {
                              return _c(
                                "div",
                                {},
                                [
                                  _c("vs-slider", {
                                    staticClass: "algolia-price-slider",
                                    attrs: {
                                      "text-fixed": "$",
                                      min: range.min,
                                      max: range.max,
                                      value: _vm.toValue(
                                        currentRefinement,
                                        range
                                      ),
                                    },
                                    on: {
                                      input: function ($event) {
                                        return refine({
                                          min: $event[0],
                                          max: $event[1],
                                        })
                                      },
                                    },
                                  }),
                                ],
                                1
                              )
                            },
                          },
                        ]),
                      }),
                      _c("vs-divider"),
                      _c("h6", { staticClass: "font-bold mb-4" }, [
                        _vm._v("Category"),
                      ]),
                      _c("ais-hierarchical-menu", {
                        attrs: { attributes: _vm.algoliaCategories },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ items, refine }) {
                              return _c("div", {}, [
                                _c(
                                  "ul",
                                  _vm._l(items, function (item) {
                                    return _c(
                                      "li",
                                      {
                                        key: item.value,
                                        staticClass:
                                          "flex items-center cursor-pointer py-1",
                                        on: {
                                          click: function ($event) {
                                            return refine(item.value)
                                          },
                                        },
                                      },
                                      [
                                        _c("feather-icon", {
                                          attrs: {
                                            icon: "CircleIcon",
                                            svgClasses: [
                                              {
                                                "text-primary fill-current":
                                                  item.isRefined,
                                              },
                                              "h-5 w-5",
                                            ],
                                          },
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "ml-2",
                                            class: {
                                              "text-primary": item.isRefined,
                                            },
                                          },
                                          [_vm._v(_vm._s(item.label))]
                                        ),
                                      ],
                                      1
                                    )
                                  }),
                                  0
                                ),
                              ])
                            },
                          },
                        ]),
                      }),
                      _c("vs-divider"),
                      _c("h6", { staticClass: "font-bold mb-4" }, [
                        _vm._v("Brand"),
                      ]),
                      _c("ais-refinement-list", {
                        attrs: { attribute: "brand" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ items, isFromSearch, refine }) {
                              return _c("div", {}, [
                                _c(
                                  "ul",
                                  [
                                    isFromSearch && !items.length
                                      ? _c("li", [_vm._v("No results.")])
                                      : _vm._e(),
                                    _vm._l(items, function (item) {
                                      return _c(
                                        "li",
                                        {
                                          key: item.value,
                                          staticClass:
                                            "mb-2 flex items-center justify-between",
                                        },
                                        [
                                          _c(
                                            "vs-checkbox",
                                            {
                                              staticClass: "ml-0",
                                              on: {
                                                click: function ($event) {
                                                  return refine(item.value)
                                                },
                                              },
                                              model: {
                                                value: item.isRefined,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "isRefined",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.isRefined",
                                              },
                                            },
                                            [_vm._v(_vm._s(item.label))]
                                          ),
                                          _c("span", [
                                            _vm._v(_vm._s(item.count)),
                                          ]),
                                        ],
                                        1
                                      )
                                    }),
                                  ],
                                  2
                                ),
                              ])
                            },
                          },
                        ]),
                      }),
                      _c("vs-divider"),
                      _c("h6", { staticClass: "font-bold mb-3" }, [
                        _vm._v("Rating"),
                      ]),
                      _c("ais-rating-menu", {
                        attrs: { attribute: "rating" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ items, refine, createURL }) {
                              return _c(
                                "ul",
                                {},
                                _vm._l(items, function (item) {
                                  return _c(
                                    "li",
                                    { key: item.value, staticClass: "mb-2" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex justify-between items-center",
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return refine(item.value)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "flex items-center flex-wrap",
                                            },
                                            [
                                              _vm._l(
                                                item.stars,
                                                function (full, index) {
                                                  return _c("feather-icon", {
                                                    key: index,
                                                    attrs: {
                                                      icon: "StarIcon",
                                                      svgClasses: [
                                                        {
                                                          "text-warning": full,
                                                          "text-grey": !full,
                                                          "ml-1": index,
                                                        },
                                                        "cursor-pointer stroke-current h-6 w-6",
                                                      ],
                                                    },
                                                  })
                                                }
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "ml-2" },
                                                [_vm._v("& up")]
                                              ),
                                            ],
                                            2
                                          ),
                                          _c("span", [
                                            _vm._v(
                                              "(" + _vm._s(item.count) + ")"
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  )
                                }),
                                0
                              )
                            },
                          },
                        ]),
                      }),
                      _c("vs-divider"),
                      _c("ais-clear-refinements", {
                        staticClass: "flex justify-center",
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ canRefine, refine, createURL }) {
                              return _c(
                                "vs-button",
                                {
                                  staticClass: "w-full",
                                  attrs: { disabled: !canRefine },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return refine.apply(null, arguments)
                                    },
                                  },
                                },
                                [_vm._v("Remove Filters")]
                              )
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                { class: { "sidebar-spacer-with-margin": _vm.clickNotClose } },
                [
                  _c("ais-search-box", {
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({
                          currentRefinement,
                          isSearchStalled,
                          refine,
                        }) {
                          return _c("div", {}, [
                            _c(
                              "div",
                              { staticClass: "relative mb-8" },
                              [
                                _c("vs-input", {
                                  staticClass:
                                    "w-full vs-input-shadow-drop vs-input-no-border d-theme-input-dark-bg",
                                  attrs: {
                                    placeholder: "Search here",
                                    size: "large",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return refine($event)
                                    },
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "esc",
                                          27,
                                          $event.key,
                                          ["Esc", "Escape"]
                                        )
                                      )
                                        return null
                                      return refine("")
                                    },
                                  },
                                  model: {
                                    value: currentRefinement,
                                    callback: function ($$v) {
                                      currentRefinement = $$v
                                    },
                                    expression: "currentRefinement",
                                  },
                                }),
                                _c(
                                  "p",
                                  {
                                    staticClass: "mt-4 text-grey",
                                    attrs: { hidden: !isSearchStalled },
                                  },
                                  [
                                    _c("feather-icon", {
                                      staticClass: "mr-2 align-middle",
                                      attrs: {
                                        icon: "ClockIcon",
                                        svgClasses: "w-4 h-4",
                                      },
                                    }),
                                    _c("span", [_vm._v("Loading...")]),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: !currentRefinement,
                                        expression: "!currentRefinement",
                                      },
                                    ],
                                    staticClass:
                                      "absolute top-0 right-0 py-4 px-6",
                                    attrs: { slot: "submit-icon" },
                                    slot: "submit-icon",
                                  },
                                  [
                                    _c("feather-icon", {
                                      attrs: {
                                        icon: "SearchIcon",
                                        svgClasses: "h-6 w-6",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: currentRefinement,
                                        expression: "currentRefinement",
                                      },
                                    ],
                                    staticClass:
                                      "absolute top-0 right-0 py-4 px-6",
                                    attrs: { slot: "reset-icon" },
                                    slot: "reset-icon",
                                  },
                                  [
                                    _c("feather-icon", {
                                      attrs: {
                                        icon: "XIcon",
                                        svgClasses: "h-6 w-6 cursor-pointer",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return refine("")
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ])
                        },
                      },
                    ]),
                  }),
                  _c("ais-hits", {
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ items }) {
                          return _c(
                            "div",
                            {},
                            [
                              _vm.currentItemView == "item-grid-view"
                                ? [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "items-grid-view vx-row match-height",
                                      },
                                      _vm._l(items, function (item) {
                                        return _c(
                                          "div",
                                          {
                                            key: item.objectID,
                                            staticClass:
                                              "vx-col lg:w-1/3 sm:w-1/2 w-full",
                                          },
                                          [
                                            _c(
                                              "item-grid-view",
                                              { attrs: { item: item } },
                                              [
                                                _c(
                                                  "template",
                                                  { slot: "action-buttons" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "flex flex-wrap",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "item-view-primary-action-btn p-3 flex flex-grow items-center justify-center cursor-pointer",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.toggleItemInWishList(
                                                                  item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("feather-icon", {
                                                              attrs: {
                                                                icon: "HeartIcon",
                                                                svgClasses: [
                                                                  {
                                                                    "text-danger fill-current":
                                                                      _vm.isInWishList(
                                                                        item.objectID
                                                                      ),
                                                                  },
                                                                  "h-4 w-4",
                                                                ],
                                                              },
                                                            }),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "text-sm font-semibold ml-2",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "WISHLIST"
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "item-view-secondary-action-btn bg-primary p-3 flex flex-grow items-center justify-center text-white cursor-pointer",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.cartButtonClicked(
                                                                  item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("feather-icon", {
                                                              attrs: {
                                                                icon: "ShoppingBagIcon",
                                                                svgClasses:
                                                                  "h-4 w-4",
                                                              },
                                                            }),
                                                            _vm.isInCart(
                                                              item.objectID
                                                            )
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "text-sm font-semibold ml-2",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "VIEW IN CART"
                                                                    ),
                                                                  ]
                                                                )
                                                              : _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "text-sm font-semibold ml-2",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "ADD TO CART"
                                                                    ),
                                                                  ]
                                                                ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              2
                                            ),
                                          ],
                                          1
                                        )
                                      }),
                                      0
                                    ),
                                  ]
                                : _vm._l(items, function (item) {
                                    return _c(
                                      "div",
                                      {
                                        key: item.objectID,
                                        staticClass: "items-list-view mb-base",
                                      },
                                      [
                                        _c(
                                          "item-list-view",
                                          { attrs: { item: item } },
                                          [
                                            _c(
                                              "template",
                                              { slot: "action-buttons" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "item-view-primary-action-btn p-3 rounded-lg flex flex-grow items-center justify-center cursor-pointer mb-3",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.toggleItemInWishList(
                                                          item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("feather-icon", {
                                                      attrs: {
                                                        icon: "HeartIcon",
                                                        svgClasses: [
                                                          {
                                                            "text-danger fill-current":
                                                              _vm.isInWishList(
                                                                item.objectID
                                                              ),
                                                          },
                                                          "h-4 w-4",
                                                        ],
                                                      },
                                                    }),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-sm font-semibold ml-2",
                                                      },
                                                      [_vm._v("WISHLIST")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "item-view-secondary-action-btn bg-primary p-3 rounded-lg flex flex-grow items-center justify-center text-white cursor-pointer",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.cartButtonClicked(
                                                          item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("feather-icon", {
                                                      attrs: {
                                                        icon: "ShoppingBagIcon",
                                                        svgClasses: "h-4 w-4",
                                                      },
                                                    }),
                                                    _vm.isInCart(item.objectID)
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-sm font-semibold ml-2",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "VIEW IN CART"
                                                            ),
                                                          ]
                                                        )
                                                      : _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-sm font-semibold ml-2",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "ADD TO CART"
                                                            ),
                                                          ]
                                                        ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    )
                                  }),
                            ],
                            2
                          )
                        },
                      },
                    ]),
                  }),
                  _c("ais-pagination", {
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({
                          currentRefinement,
                          nbPages,
                          pages,
                          isFirstPage,
                          isLastPage,
                          refine,
                          createURL,
                        }) {
                          return _c(
                            "div",
                            {},
                            [
                              _c("vs-pagination", {
                                attrs: {
                                  total: nbPages,
                                  max: 7,
                                  value: currentRefinement + 1,
                                },
                                on: {
                                  input: (val) => {
                                    refine(val - 1)
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        },
                      },
                    ]),
                  }),
                  _c("img", {
                    staticClass: "flex mt-4 mx-auto h-8",
                    attrs: {
                      src: require("@/assets/images/pages/eCommerce/Algolia-logo.png"),
                      alt: "algolia-logo",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }